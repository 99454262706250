import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout, { MainContainerDiv, PageTitle } from "../components/Layout";
import { Bio } from "./__generated__/Bio";

export default (props: PageProps<Bio>) => {
	console.log({ props })
	return (
		<Layout title="Bio">
			<MainContainerDiv>
				<PageTitle>Bio</PageTitle>
				<div dangerouslySetInnerHTML={{ __html: props.data!.allMarkdownRemark.nodes[0].html! }} />
			</MainContainerDiv>
		</Layout>
	);
};

export const pageQuery = graphql`
	query Bio {
		allMarkdownRemark(
			filter: {
				fileAbsolutePath: {regex: "/data\\/bio\\.md/"},
				frontmatter: {published: {eq: true}}
			},
			sort: {fields: frontmatter___date, order: DESC}
		) {
			nodes {
				frontmatter {
					date(formatString: "dddd, Do MMMM YYYY")
				}
				html
			}
		}
	}
`;